@tailwind base;
@tailwind components;
@tailwind utilities;

/* leaflet-container small and medium screen rules */
/* @media (max-width: 767px) {
  .leaflet-container {
    height: 85.5vh !important;
  }

  .footer-bar {
    height: 15vh !important;

  }
} */

.content-page {
  height: 92.5vh !important;
}

.leaflet-container {
  max-height: 92.5vh !important;
}

.footer {
  height: 7.5vh !important;

}

@media (max-width: 767px) {
  .leaflet-container {
    height: 85.5vh !important;
  }

  .footer-bar {
    height: 15vh !important;

  }
}

a {
  /* color: #695bee !important; */
  text-decoration: underline;
}

